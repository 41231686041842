import './App.css'

const App = () => {
  return (
    <div className="imageCss">
      <img src='https://ik.imagekit.io/gdmyfaarn/under_construction.png?updatedAt=1715276742583' alt="Under Construction" />
    </div>
  );
}

export default App;
